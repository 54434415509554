import React from "react";

const BookLeftBar = () => {
  return (
    <div className="panel">
      <div class="SRPCover bookCover">
        <a
          href="#"
          aria-controls="seeImage"
          class="coverLook dialog--open cboxElement illustration"
          title="Pull up a bigger book cover"
        >
          <img
            src="/images/books/1.jpg"
            class="cover"
            alt="Cover of: A Court of Mist and Fury by Sarah J. Maas"
          />
        </a>
      </div>
      <div class="cta-button-group">
    <a class="cta-btn cta-btn--preview cboxElement" href="#">Batafsil <span>o'qish</span></a>
  </div>
    </div>
  );
};

export default BookLeftBar;
