import React from 'react';
import BookLeftBar from './BookLeftBar';
import BookPreview from './BookPreview';

const BookContent = () => {
    return (
        <div className='contentBody'>
            <div className='workDetails'>
            <BookLeftBar/>
            <BookPreview/>
            </div>
        </div>
    );
}

export default BookContent;
