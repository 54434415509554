import React from "react";

const BookPreview = () => {
  return (
    <div className="editionAbout">
      <div className="work-title-and-author desktop">
        <span>
          <h1 className="work-title" itemprop="name">
            Yulduzli tunlar
          </h1>

          <h2 className="edition-byline">
            Muallif:{" "}
            <a href="/authors/OL7115219A/Sarah_J._Maas" itemprop="author">
              O'tkir Hoshimov
            </a>
          </h2>
        </span>
      </div>
      <div className="book-description read-more">
        <p>Kitob haqida:</p>
        <div className="read-more__content">
          <p>
            Feyre has undergone more trials than one human woman can carry in
            her heart. Though she's now been granted the powers and lifespan of
            the High Fae, she is haunted by her time Under the Mountain and the
            terrible deeds she performed to save the lives of Tamlin and his
            people.
          </p>
          <p>
            As her marriage to Tamlin approaches, Feyre's hollowness and
            nightmares consume her. She finds herself split into two different
            people: one who upholds her bargain with Rhysand, High Lord of the
            feared Night Court, and one who lives out her life in the Spring
            Court with Tamlin. While Feyre navigates a dark web of politics,
            passion, and dazzling power, a greater evil looms. She might just be
            the key to stopping it, but only if she can harness her harrowing
            gifts, heal her fractured soul, and decide how she wishes to shape
            her future-and the future of a world in turmoil.
          </p>
          <p>
            Bestselling author Sarah J. Maas's masterful storytelling brings
            this second book in her dazzling, sexy, action-packed series to new
            heights.
          </p>
        </div>
      </div>
      <a href="/" className="back__btn">Ortga</a>
    </div>
  );
};

export default BookPreview;
