import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import dataSlide from "../../../../data/db.json";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import BookGanreDetails from "./BookGanreDetailsUz";
const BookGanre = () => {
  const breakpoints = {
    // when window width is >= 320px
    // when window width is >= 320px
    100: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    992: {
      slidesPerView: 6,
      spaceBetween: 0,
    },
  };
  const [slideInfo, setSlideData] = useState(dataSlide);
  return (
    <div className="box__ganre">
      <h2 className="home-h2">O'zbek adabiyoti</h2>
      <div className="main__top__slider">
        <Swiper
          breakpoints={breakpoints}
          slidesPerView={3}
          loop={true}
          pagination={false}
          navigation={{
            nextEl: ".book-next_ganre",
            prevEl: ".book-prev_ganre",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper2"
        >
          {slideInfo.map((elems) => (
            <SwiperSlide key={elems.id}>
              <BookGanreDetails ganreContent={elems} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="book__navigator ganre__navigator">
        <button className="book-prev_ganre">
          <ChevronLeft />
        </button>
        <button className="book-next_ganre">
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BookGanre;
