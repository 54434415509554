import React from "react";

const Login = () => {
  return (
    <div id="test-body-mobile">
      <div className="flash-messages"></div>
      <div id="contentHead">
        <h1>Log In</h1>
      </div>
      <div id="contentBody">
        <p>Hemis orqali ro'yxatdan o'tish</p>

        <form
          id="register"
          className="login olform entry__login"
          name="register"
          method="post"
        >
          <div className="formElement">
            <div className="label">
              <label for="username">Email</label>
              <span className="smaller lighter"></span>
            </div>

            <div className="input">
              <input
                type="email"
                className="required"
                id="username"
                name="username"
                placeholder="Elektron pochtani kiriting..."
                autocapitalize="off"
                autocorrect="off"
              />
              <span className="invalid clearfix" htmlfor="username"></span>
            </div>
          </div>

          <div className="formElement">
            <div className="label">
              <label for="password">Password</label>
              <span className="smaller lighter"></span>
            </div>
            <div className="input">
              <input
                type="password"
                className="required"
                placeholder="Password"
                id="password"
                name="password"
              />
              <span className="invalid clearfix" htmlfor="password"></span>
            </div>
          </div>

          <div className="formElement remember__check">
            <div className="input">
              <input name="remember" type="checkbox" id="remember" />
              <label for="remember" className="small ">
                Remember me
              </label>
            </div>
          </div>
          <input
            type="hidden"
            id="redirect"
            value="/account/books"
            name="redirect"
          />
          <input type="hidden" id="debug_token" value="" name="debug_token" />
          <div className="clearfix"></div>
          <div className="formElement bottom">
            <button
              value="Log In"
              type="submit"
              className="login-submit larger"
              name="login"
              title="Log In"
            >
              Kirish
            </button>
          </div>
          <div className="formElement">
            <div className="label"></div>
            <div className="input smaller">
              <a href="https://archive.org/account/login.forgotpw.php">
                Parolni tiklash
              </a>
              <br />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
