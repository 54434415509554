import React, { useRef } from "react";
import {
  Search,
  List,
  XLg,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  const menuRef = useRef();
  const maskRef = useRef();
  const showMenu = () => {
    menuRef.current.classList.add("active__nav");
    maskRef.current.classList.toggle("active__mask");
  };
  const closeMenu = () => {
    menuRef.current.classList.remove("active__nav");
    maskRef.current.classList.remove("active__mask");
  };
  const closeMegaMenu = (e) => {
    e.target.classList.remove('active__mask');
    menuRef.current.classList.remove('active__nav')
  };
  return (
    <div className="hamburger-component header-dropdown">
      <div className="main__wrapper__all">
        <div className="menu_bars" data-ol-link-track="HeaderBar|Hamburger">
          <List onClick={showMenu} />
        </div>
        <div className="mask-menu" onClick={closeMegaMenu} ref={maskRef}></div>
        <div className="app-drawer" ref={menuRef}>
          <ul className="dropdown-menu hamburger-dropdown-menu">
            <li className="subsection">Ferpi Open Library</li>
            <li className="login-links">
              <Link to="/login" className="login-links__secondary">
                Login
              </Link>
              <Link to="/resgister" className="login-links__primary">
                Registratsiya
              </Link>
              <a className="closeMenu" onClick={closeMenu}>
                <i>
                  <XLg />
                </i>
              </a>
            </li>
            <li className="search__data__block">
              <form action="#">
                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Qidiruv"
                />
                <button type="submit">
                  <Search />
                </button>
              </form>
            </li>
            <li className="subsection">Kitoblar dunyosi</li>
            <li>
              <a href="/subjects" data-ol-link-track="Hamburger|Subjects">
                O'zkeb adabiyoti
              </a>
            </li>
            <li>
              <a href="/trending" data-ol-link-track="Hamburger|Trending">
                Mashhur kitoblar
              </a>
            </li>
            <li>
              <a href="/explore" data-ol-link-track="Hamburger|Explore">
                Jahon adabiyoti
              </a>
            </li>
            <li>
              <a href="/collections" data-ol-link-track="Hamburger|Collections">
                Ilmiy kitoblar
              </a>
            </li>
            <li>
              <a href="/k-12" data-ol-link-track="Hamburger|K12Library">
                Baadiy kitoblar
              </a>
            </li>
            <li>
              <a href="/booktalks" data-ol-link-track="Hamburger|BookTalks">
                Audio kitoblar
              </a>
            </li>
            <li className="subsection">Aloqa</li>
            <li>
              <a href="/help" data-ol-link-track="Hamburger|Help">
                73 241 12 06
              </a>
            </li>
            <li>
              <a
                href="/developers"
                data-ol-link-track="Hamburger|DevelopersHelp"
              >
                ferpi_info@edu.uz
              </a>
            </li>
            <li>
              <a
                href="/librarians"
                data-ol-link-track="Hamburger|LibrariansHelp"
              >
                150100, Farg'ona shahar, <br /> Farg'ona ko'chasi 86-uy
              </a>
            </li>
            <li>
              <a
                href="/librarians"
                data-ol-link-track="Hamburger|LibrariansHelp"
              >
                Avtobus yo'nalishlari: 8, 8A, 9, 10, 14
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
