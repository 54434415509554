import React from 'react';

const Mybooks = () => {
    return (
        <div>
            <center><h2>My Books</h2></center>
        </div>
    );
}

export default Mybooks;
