import React from "react";
import BookItemsWelcome from "./category/welcome/BookItemsWelcome";
import BookGanreUz from "./category/uz/BookGanreUz";
import BookGanreWorld from "./category/jahon/BookGanreWorld";
import BookGanreScientific from "./category/ilmiy/BookGanreScientific";
const BookList = () => {
  return (
    <div>
      <BookItemsWelcome />
      <BookGanreUz />
      <BookGanreWorld />
      <BookGanreScientific />
    </div>
  );
};

export default BookList;
