import React from "react";
import fpLogo from "../images/fpi-u.png";
import { Search, ArrowDown, ChevronDown, List } from "react-bootstrap-icons";
import MegaMenu from "./MegaMenu";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div>
      <header id="header-bar" className="header-bar">
        <div className="logo-component">
          <Link
            to="/"
            title="The Internet Archive's Open Library: One page for every book"
          >
            <div className="logo-txt">
              <img
                className="logo-icon"
                src={fpLogo}
                width="189"
                height="150"
                alt="Open Library logo"
              />
            </div>
          </Link>
        </div>

        <ul className="navigation-component">
          <li>
            <div className="mybooks-component header-dropdown">
              <Link to="/mybooks" data-ol-link-track="MainNav|MyBooks">
                My Books
              </Link>
            </div>
          </li>
          <li>
            <div className="browse-component header-dropdown">
              <div className="filter__list">
                <div className="center__elems">
                  <div>Filter</div>
                  <ChevronDown />
                  <div className="browse-dropdown-component navigation-dropdown-component">
                    <ul className="dropdown-menu browse-dropdown-menu">
                      <li>
                        <Link
                          to="/allbooks"
                          data-ol-link-track="MainNav|Subjects"
                        >
                          Barcha kitoblar
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/trendbooks"
                          data-ol-link-track="MainNav|Trending"
                        >
                          Mashhur kitoblar
                        </Link>
                      </li>
                      <li>
                        <a href="/explore" data-ol-link-track="MainNav|Explore">
                          Library Explorer
                        </a>
                      </li>
                      <li>
                        <a href="/lists" data-ol-link-track="MainNav|Lists">
                          Lists
                        </a>
                      </li>
                      <li>
                        <a
                          href="/collections"
                          data-ol-link-track="MainNav|Collections"
                        >
                          Kolleksiya
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="search-component">
          <div className="search-bar-component">
            <div className="search-bar">
              <div className="search-facet">
                <label className="search-facet-selector">
                  <select aria-label="Search by">
                    <option value="all">
                      Barcha
                      <option className="dropdown__item">
                        <ChevronDown />
                      </option>
                    </option>
                    <option value="title">Title</option>
                    <option value="author">Author</option>
                    <option value="text">Text</option>
                    <option value="subject">Subject</option>
                    <option value="lists">Lists</option>
                    <option value="advanced">Advanced</option>
                  </select>
                </label>
              </div>
              <form className="search-bar-input" action="/search" method="get">
                <input
                  type="text"
                  name="q"
                  placeholder="Qidiruv"
                  aria-label="Search"
                  autocomplete="off"
                />
                <input
                  name="mode"
                  type="checkbox"
                  aria-hidden="true"
                  aria-label="Search checkbox"
                  checked
                  value="everything"
                  id="ftokenstop"
                  className="hidden instantsearch-mode"
                />
                <div className="vertical-separator"></div>
                <input
                  type="submit"
                  value=""
                  className="search-bar-submit"
                  aria-label="Search submit"
                />
              </form>
            </div>
            <div className="search-dropdown">
              <ul className="search-results"></ul>
            </div>
          </div>
        </div>

        <ul className="auth-component">
          <li className="hide-me">
            <Link to="/login" className="btn">
              Kirish
            </Link>
          </li>
          <li>
            <Link to="/register" className="btn primary">
              Registratsiya
            </Link>
          </li>
        </ul>

        <MegaMenu />
      </header>
    </div>
  );
};

export default Navbar;
