import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <center>
                <h2>Sahifa topilmadi</h2>
            </center>
        </div>
    );
}

export default NotFoundPage;
