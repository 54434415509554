import React from "react";
import {
  Facebook,
  Instagram,
  Telegram,
  Youtube,
} from "react-bootstrap-icons";
const Footer = () => {
  return (
    <div>
      <footer>
        <div id="footer-content">
          <div id="footer-links">
            <div>
              <h2>Ferpi Open Library</h2>
              <ul>
                <li>
                  <a href="/about/vision">Barcha kitoblar</a>
                </li>
                <li>
                  <a href="/volunteer">Jahon adaboyoti</a>
                </li>
                <li>
                  <a href="/partner-with-us">O'zbek adabiyoti</a>
                </li>
                <li>
                  <a href="#" title="Jobs">
                    Maqolalar
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2>Bog'lanish</h2>
              <ul>
                <li>
                  <a href="/" title="Go home">
                    73 241 12 06
                  </a>
                </li>
                <li>
                  <a href="/search" title="Explore Books">
                    ferpi_info@edu.uz
                  </a>
                </li>
                <li>
                  <a href="/search/authors" title="Explore authors">
                    150100, Farg'ona shahar, Farg'ona ko'chasi 86-uy
                  </a>
                </li>
                <li>
                  <a href="/subjects" title="Explore subjects">
                    Avtobus yo'nalishlari: 8, 8A, 9, 10, 14
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2>Bizni ijtimoiy tarmoqlarda kuzatib boring</h2>
              <ul>
                <li>
                  <a href="https://www.google.ru/maps/place/Ferghana+polytechnic+institute/@40.4170548,71.7549193,14.75z/data=!4m9!1m2!2m1!1sfarpi!3m5!1s0x38bb82d713bba655:0xd9b610b729b574fb!8m2!3d40.4220059!4d71.7733192!15sCgVmYXJwaVoHIgVmYXJwaZIBFHRlY2huaWNhbF91bml2ZXJzaXR5mgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU5CZW5ONU4wTlJFQUU">
                    Xarita
                  </a>
                </li>
              </ul>
              <aside>
                <a
                  class="footer-icon"
                  title="Twitter"
                  href="https://twitter.com/OpenLibrary"
                >
                  <Telegram />
                </a>
                <a class="footer-icon" title="GitHub" href="#">
                  <Facebook />
                </a>
                <a class="footer-icon" title="GitHub" href="#">
                  <Instagram />
                </a>
                <a class="footer-icon" title="GitHub" href="#">
                  <Youtube />
                </a>
              </aside>
            </div>
          </div>
          <hr />
          <div id="footer-details">
            <img
              id="archive-logo"
              src="/images/fpi-u.png"
              alt="Open Library logo"
              loading="lazy"
            />
            <div id="legal-details">
              <span>Farg'ona politexnika instituti onlain kutubxonasi</span>
            </div>
            <div id="version-details">
              <span>RTTM markazi tomonidan yaratilgan. &copy;2024</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
