import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useState, useEffect } from "react";
const BookDetails = ({ sliderData }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <div className="tutorial__item">
        <a href="#">
          {loading ? (
            <Skeleton count={1} height={100} width={100} />
          ) : (
            <img src={sliderData.imgslide} alt="book1" />
          )}
          <div className="card__text">
            {loading ? (
              <Skeleton count={1} width={100} />
            ) : (
              <p>{sliderData.title}</p>
            )}
            {loading ? (
              <Skeleton count={1} width={100} />
            ) : (
              <p className="small">{sliderData.content}</p>
            )}
          </div>
        </a>
      </div>
    </>
  );
};

export default BookDetails;
