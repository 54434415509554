import React from "react";

const AllBooks = () => {
  return (
    <div id="contentBody">
      <form action="/search/subjects" class="olform">
        <p>
          <input
            type="text"
            name="q"
            id="searchSubjects"
            size="100"
            class="larger"
            value=""
          />
          <input type="submit" class="large" value="Search" />
        </p>
      </form>
      <div id="subjectsPage">
        <h3>Arts</h3>
        <ul>
          <li>
            {" "}
            <a href="/subjects/architecture">Architecture</a>
          </li>
          <li>
            {" "}
            <a href="/subjects/art__art_instruction">Art Instruction</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/history_of_art__art__design_styles">
              Art History
            </a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/dance">Dance</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/design">Design</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/fashion">Fashion</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/film">Film</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/graphic_design">Graphic Design</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/music">Music</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/music_theory">Music Theory</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/painting__paintings">Painting</a>{" "}
          </li>
          <li>
            {" "}
            <a href="/subjects/photography">Photography</a>{" "}
          </li>
        </ul>
        <h3>Animals</h3>
        <ul>
          <li>
            <a href="/subjects/bears">Bears</a>{" "}
          </li>
          <li>
            <a href="/subjects/cats">Cats</a>{" "}
          </li>
          <li>
            <a href="/subjects/kittens">Kittens</a>{" "}
          </li>
          <li>
            <a href="/subjects/dogs">Dogs</a>{" "}
          </li>
          <li>
            <a href="/subjects/puppies">Puppies</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/fiction">Fiction</a>
        </h3>
        <ul>
          <li>
            <a href="/subjects/fantasy">Fantasy</a>{" "}
          </li>
          <li>
            <a href="/subjects/historical_fiction">Historical Fiction</a>{" "}
          </li>
          <li>
            <a href="/subjects/horror">Horror</a>{" "}
          </li>
          <li>
            <a href="/subjects/humor">Humor</a>{" "}
          </li>
          <li>
            <a href="/subjects/literature">Literature</a>{" "}
          </li>
          <li>
            <a href="/subjects/magic">Magic</a>{" "}
          </li>
          <li>
            <a href="/subjects/mystery_and_detective_stories">
              Mystery and detective stories
            </a>{" "}
          </li>
          <li>
            <a href="/subjects/plays">Plays</a>{" "}
          </li>
          <li>
            <a href="/subjects/poetry">Poetry</a>{" "}
          </li>
          <li>
            <a href="/subjects/romance">Romance</a>{" "}
          </li>
          <li>
            <a href="/subjects/science_fiction">Science Fiction</a>{" "}
          </li>
          <li>
            <a href="/subjects/short_stories">Short Stories</a>{" "}
          </li>
          <li>
            <a href="/subjects/thriller">Thriller</a>{" "}
          </li>
          <li>
            <a href="/subjects/young_adult_fiction">Young Adult</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/sciencemathematics">Science &amp; Mathematics</a>
        </h3>
        <ul>
          <li>
            <a href="/subjects/biology">Biology</a>{" "}
          </li>
          <li>
            <a href="/subjects/chemistry">Chemistry</a>{" "}
          </li>
          <li>
            <a href="/subjects/mathematics">Mathematics</a>{" "}
          </li>
          <li>
            <a href="/subjects/physics">Physics</a>{" "}
          </li>
          <li>
            <a href="/subjects/programming">Programming</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/business">Business &amp; Finance</a>
        </h3>
        <ul>
          <li>
            <a href="/subjects/management">Management</a>{" "}
          </li>
          <li>
            <a href="/subjects/entrepreneurship">Entrepreneurship</a>{" "}
          </li>
          <li>
            <a href="/subjects/business__economics">Business Economics</a>{" "}
          </li>
          <li>
            <a href="/subjects/success_in_business">Business Success</a>{" "}
          </li>
          <li>
            <a href="/subjects/finance">Finance</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/juvenile_fiction">Children's</a>
        </h3>
        <ul>
          <li>
            <a href="/subjects/juvenile_literature">Kids Books</a>{" "}
          </li>
          <li>
            <a href="/subjects/stories_in_rhyme">Stories in Rhyme</a>{" "}
          </li>
          <li>
            <a href="/subjects/infancy">Baby Books</a>{" "}
          </li>
          <li>
            <a href="/subjects/bedtime">Bedtime Books</a>{" "}
          </li>
          <li>
            <a href="/subjects/picture_books">Picture Books</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/biography">Biography</a>
        </h3>
        <ul>
          <li>
            <a href="/subjects/autobiography">Autobiographies</a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=History">
              History
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=Politics+and+government">
              Politics and Government
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=World+War%2C+1939-1945">
              World War II
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=Women">
              Women
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=Kings+and+rulers">
              Kings and Rulers
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=Composers">
              Composers
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ABiography&amp;subject_facet=Biography&amp;subject_facet=Artists">
              Artists
            </a>{" "}
          </li>
        </ul>
        <h3>Social Sciences</h3>
        <ul>
          <li>
            <a href="/subjects/anthropology">Anthropology</a>{" "}
          </li>
          <li>
            <a href="/subjects/religion">Religion</a>{" "}
          </li>
          <li>
            <a href="/subjects/political_science">Political Science</a>{" "}
          </li>
          <li>
            <a href="/subjects/psychology">Psychology</a>{" "}
          </li>
        </ul>
        <h3>Places</h3>
        <ul>
          <li>
            <a href="/subjects/place%3Abrazil">Brazil</a>{" "}
          </li>
          <li>
            <a href="/subjects/place%3Aindia">India</a>{" "}
          </li>
          <li>
            <a href="/subjects/place%3Aindonesia">Indonesia</a>{" "}
          </li>
          <li>
            <a href="/subjects/place%3Aunited_states">United States</a>{" "}
          </li>
        </ul>
        <h3>
          <a href="/subjects/textbooks">Textbooks</a>
        </h3>
        <ul>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=History">
              History
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Mathematics">
              Mathematics
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Geography">
              Geography
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Psychology">
              Psychology
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Algebra">
              Algebra
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Education">
              Education
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbook+subject%3ABusiness&amp;subject_facet=Textbooks">
              Business &amp; Economics
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Science">
              Science
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Chemistry">
              Chemistry
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=English+language">
              English Language
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3ATextbooks&amp;subject_facet=Textbooks&amp;subject_facet=Physics">
              Physics
            </a>{" "}
          </li>
          <li>
            <a href="/search?q=subject%3A%22Computer+science%22&amp;sort=editions&amp;subject_facet=Computer+science">
              Computer Science
            </a>{" "}
          </li>
        </ul>
        <h3>Books by Language</h3>
        <ul>
          <li>
            <a href="/search?q=language%3Aeng">English</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Afre">French</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Aspa">Spanish</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Ager">German</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Arus">Russian</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Aita">Italian</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Achi">Chinese</a>{" "}
          </li>
          <li>
            <a href="/search?q=language%3Ajpn">Japanese</a>{" "}
          </li>
          <li>
            <a href="/languages">See more...</a>{" "}
          </li>
        </ul>
      </div>
      <hr />
      <h3>What's a subject heading?</h3>
      <p>
        As the wise Wikipedia says: "The Library of Congress Subject Headings
        (LCSH) comprise a thesaurus (in the information science sense, a
        controlled vocabulary) of subject headings, maintained by the United
        States Library of Congress, for use in bibliographic records. LC Subject
        Headings are an integral part of bibliographic control, which is the
        function by which libraries collect, organize, and disseminate
        documents.... Subject headings are normally applied to every item within
        a library's collection and facilitate a user's access to items in the
        catalog that pertain to similar subject matter."
      </p>
      <p>
        Side note: Interestingly, professional library catalogers are often
        restricted to a limit of 3 in the number of subjects they may use. We
        collect general subjects, places mentioned, people mentioned, and even
        the time period the book is about. Feel free to imitate or enhance
        existing headings, or create an entirely new one. Or two. Or three. Or
        five.
      </p>

      <div class="clearfix"></div>
    </div>
  );
};

export default AllBooks;
