import React from "react";
import logo from "../images/ferpi_l4mode.png";
import { Globe } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <div id="topNotice">
        <div className="page-banner page-banner-black page-banner-center">
          <div className="iaBar">
            <Link to="/" className="iaLogo">
              <img alt="FerPi logo" src={logo} width="160" />
            </Link>
            <div className="language-component header-dropdown">
              <details className="globe__status">
                <summary>
                  <Globe/>
                </summary>
                <div className="language-dropdown-component">
                  <ul className="dropdown-menu">
                    <li>
                      <a href="#" lang="uz" data-lang-id="uz" title="Uz">
                        UZ
                      </a>
                    </li>
                    <li>
                      <a href="#" lang="ru" data-lang-id="ru" title="Ru">
                        RU
                      </a>
                    </li>
                    <li>
                      <a href="#" lang="en" data-lang-id="en" title="En">
                        EN
                      </a>
                    </li>
                  </ul>
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
