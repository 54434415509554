import React from "react";

const TrendBooks = () => {
  return (
    <div id="contentBody">
      <h1>Trending Books: Now</h1>
      <p>See what readers from the community are adding to their bookshelves</p>
      <div className="mybooks-list">
        <ul className="list-books">
          <li
            class="searchResultItem"
            itemscope=""
            itemtype="https://schema.org/Book"
          >
            <span class="bookcover ">
              <a href="/works/OL28345055W">
                <img
                  itemprop="image"
                  src="/images/books/1.jpg"
                  alt="Cover of: Peppa Pig"
                  title="Cover of: Peppa Pig"
                />
              </a>
            </span>

            <div class="details">
              <div class="resultTitle">
                <h3 itemprop="name" class="booktitle">
                  <a itemprop="url" href="/works/OL28345055W" class="results">
                    Peppa Pig
                  </a>
                </h3>
              </div>
              <span
                itemprop="author"
                itemscope=""
                itemtype="https://schema.org/Organization"
                class="bookauthor"
              >
                by{" "}
                <a href="/authors/OL7224948A" class="results">
                  Peppa Pig
                </a>
              </span>
              <span class="resultPublisher">
                <span class="publishedYear">First published in 2013</span>
                <a href="/works/OL28345055W?mode=all#editions-list">
                  18 editions
                </a>
              </span>
              Someone marked as Want to Read 13 seconds ago
            </div>

            <div class="searchResultItemCTA">
              <div class="searchResultItemCTA-lending">
                <div class="cta-button-group">
                  <a
                    class="cta-btn"
                    href="/works/OL28345055W"
                    data-ol-link-track="CTAClick|NotInLibrary"
                  >
                    Not in Library
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TrendBooks;
