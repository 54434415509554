import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import dataSlide from "../../../../data/db.json";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import BookDetailsWelcome from "./BookDetailsWelcome";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

export default function App() {
  const breakpoints = {
    // when window width is >= 320px
    100: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  };
  const [slideInfo, setSlideData] = useState(dataSlide);
  return (
    <>
      <div className="box__top__slider">
        <h2 class="home-h2">Welcome to Ferpi electron Library</h2>
        <div className="main__top__slider">
          <Swiper
            breakpoints={breakpoints}
            slidesPerView={3}
            loop={true}
            pagination={false}
            navigation={{
              nextEl: ".book-next",
              prevEl: ".book-prev",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {slideInfo.map((items) => (
              <SwiperSlide key={items.id}>
                <BookDetailsWelcome sliderData={items} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="book__navigator">
          <button className="book-prev">
            <ChevronLeft />
          </button>
          <button className="book-next">
            <ChevronRight />
          </button>
        </div>
      </div>
    </>
  );
}
