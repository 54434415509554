import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom"; // Use Link for internal navigation
import "react-loading-skeleton/dist/skeleton.css";

const BookGanreDetails = ({ ganreContent, loadingDelay = 1000 }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, loadingDelay);

    return () => clearTimeout(timeoutId); // Clear timeout on component unmount
  }, [loading, loadingDelay]);

  return (
    <div>
      <div className="book carousel__item slick-slide slick-current slick-active">
        {loading ? (
          <Skeleton count={1} height={100} width={100} />
        ) : (
          <Link to="/readbook">
            <div className="book-cover">
              <img
                className="bookcover"
                loading="lazy"
                src={ganreContent.imgBook}
                alt={ganreContent.name}
              />
            </div>
          </Link>
        )}
        {loading ? (
          <Skeleton count={2} height={20} />
        ) : (
          <div className="book-cta">
            <div className="cta-button-group">
              <Link
                className="cta-btn"
                to="/readbook"
                tabIndex="0"
              >
                {ganreContent.name}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookGanreDetails;
