import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./static/main.css";
import "./static/responsive.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import BookList from "./components/book/BookList";
import Mybooks from "./components/menu/Mybooks";
import NotFoundPage from "./components/NotFoundPage";
import Login from "./components/register/login";
import Register from "./components/register/register";
import Footer from "./components/Footer";
import AllBooks from "./components/menu/AllBooks";
import TrendBooks from "./components/menu/TrendBooks";
import BookContent from "./components/pages/read__book/BookContent";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Navbar />
      <div id="test-body-mobile">
        <Routes>
          <Route path="/" element={<BookList />} />
          <Route path="/mybooks" element={<Mybooks />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/allbooks" element={<AllBooks />} />
          <Route path="/trendbooks" element={<TrendBooks />} />
          <Route path="/readbook" element={<BookContent />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
